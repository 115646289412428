<div class="container">
  <div class="card-body"><img class="" src="../../../assets/img/vallen-en-55.png" alt="">
    <div class="card-title">
      Yes, you are in the right place! <br>
      We are not. We are trying to get there soon.
    </div>
    <p class="card-text">Sorry for the inconvenience, but we’re performing some maintenance at the moment.</p>
  </div>
  <div class="bg-color"><img class="" src="../../../assets/img/plug-graphic.png" alt="Site under maintenance"></div>
</div>

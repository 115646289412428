import { Injectable, Inject } from '@angular/core';
import { SearchOptions, SearchQuery } from './search.model';
import { environment } from 'src/environments/environment';
import algoliasearch from 'algoliasearch';
import { Store } from '@ngrx/store';
import { AppStateInterface } from '../../../types/app-state.interface';
import { UserService } from '../user/user.service';
import { UserModel } from '../user/user.model';
import { WINDOW } from 'src/app/app.module';
import { PermissionService } from '../permission/permission.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  client = algoliasearch(environment.algolia.appId, environment.algolia.apiKey);
  customerCompanyNumber?: number;
  quickListId?: number;
  user?: UserModel;
  contractOnly: boolean = false;

  constructor(private store: Store<AppStateInterface>, private userService: UserService, @Inject(WINDOW) private window: Window, private permissionService: PermissionService,) {
    this.store.select('user').subscribe((data) => {
      const context = data.user?.userTypeId === 'POU' ? data.shoppingContext : data.user?.currentShoppingAccount;
      this.customerCompanyNumber = data?.shoppingContext?.customerCompanyNumber?.id || undefined;
      this.quickListId = context?.quickListHeader?.id || undefined;
      this.user = data.user || undefined;
      this.contractOnly = this.permissionService.contractOnly();
    });
  }

  indexName = this.getIndexNames();

  private getIndexNames() {
    const isCaTestEnvironment = this?.window?.location.hostname.includes('ca-test');
    const suffix = isCaTestEnvironment ? '_test' : '';
    return {
      products: `products_${environment.algolia.site}${suffix}`,
      products_asc: `products_${environment.algolia.site}${suffix}_title_asc`,
      products_desc: `products_${environment.algolia.site}${suffix}_title_desc`,
      categories: `categories_${environment.algolia.site}${suffix}`,
      customers: `customers_${environment.algolia.site}${suffix}`,
      salesRep: `salesrep_${environment.algolia.site}${suffix}`,
      vendors: `vendors_${environment.algolia.site}${suffix}`,
      product_sugg: `products_${environment.algolia.site}_query_suggestions`,
    };
  }

  unifiedSearch(query: string) {
    // main search, using multiple indexes
    let queries: SearchQuery[] = [
      {
        indexName: this.indexName.categories,
        query: query,
        params: {
          hitsPerPage: 5,
        },
        facetFilters: [['level:1', 'level:2', 'level:3']],
        filters: this.applySearchFilters(this.indexName.categories),
        distinct: 1,
      },
      {
        indexName: this.indexName.products,
        query: query,
        params: {
          hitsPerPage: 5,
        },
        filters: this.applySearchFilters(this.indexName.products),
        distinct: 1,
      },
      {
        indexName: this.indexName.product_sugg,
        query: query,
        params: {
          hitsPerPage: 5,
        },
      },
    ];

    if (this.quickListId && !this.contractOnly) {
      queries.push({
        indexName: this.indexName.products,
        query: query,
        params: {
          hitsPerPage: 5,
        },
        facetFilters: [[`quickList:${this.quickListId}`]],
        filters: this.applySearchFilters(this.indexName.products),
        distinct: 1,
      });
    }

    return this.client.multipleQueries(queries);
  }

  getProduct(urlId: string) {
    return this.client.initIndex(this.indexName.products).search('', {
      facetFilters: 'urlId:' + urlId,
      filters: this.applySearchFilters(this.indexName.products),
      distinct: 1,
    });
  }

  getCategory(url: string) {
    return this.client.initIndex(this.indexName.categories).search('', {
      facetFilters: 'url:' + url,
      filters: this.applySearchFilters(this.indexName.categories),
    });
  }

  getProductResults(query: string, requestOptions: SearchOptions, indexName?: string, clearCache?: boolean) {
    if (!indexName) {
      indexName = this.indexName.products;
    }
    // return limited number of facets if no other filter is applied
    if (!requestOptions.facets) {
      requestOptions.facets =
        requestOptions.facetFilters && requestOptions.facetFilters.length > 0 ? ['*'] : ['categories.lvl0'];
    }
    if (!requestOptions.hitsPerPage) {
      requestOptions.hitsPerPage = 24;
    }
    requestOptions.distinct = 1;
    requestOptions.filters = this.applySearchFilters(indexName);
    if (clearCache) {
      // used on pages like 'clearance', where search term never changes, but filters do
      this.client.clearCache();
    }
    return this.client.initIndex(indexName).search(query, requestOptions);
  }

  getCustomerResults(query: string) {
    return this.client.initIndex(this.indexName.customers).search(query, {});
  }

  getVendors(query: string, requestOptions: SearchOptions) {
    if (!requestOptions.facets) {
      requestOptions.facets =
        requestOptions.facetFilters && requestOptions.facetFilters.length > 0 ? ['*'] : ['categories.lvl0'];
    }
    if (!requestOptions.hitsPerPage) {
      requestOptions.hitsPerPage = 24;
    }
    return this.client.initIndex(this.indexName.vendors).search(query, requestOptions);
  }

  getCustomer(customerNumber: string | number) {
    return this.client
      .initIndex(this.indexName.customers)
      .search('', { facetFilters: 'customerNumber:' + customerNumber });
  }

  getSalesRepResults(query: string) {
    return this.client.initIndex(this.indexName.salesRep).search(query, {});
  }

  getSalesRep(salesRepId: string) {
    return this.client.initIndex(this.indexName.salesRep).search('', { facetFilters: 'salesRepId:' + salesRepId });
  }

  public applySearchFilters(indexName?: string): string {
    const filters = [];

    if (indexName?.includes('products_')) {
      // user type filter - only apply to product searches
      filters.push(this.userService.isBuyer(this.user) ? 'isB2B:true' : 'isB2C:true');

      // contract-only users are only allowed to see items from their quicklist
      if (this.contractOnly) {
        filters.push(`quickList:${this.quickListId}`);
      }

      // personal code searching
      if (this.quickListId) {
        filters.push(`(canView:All OR canView:QL_${this.quickListId})`);
      } else {
        filters.push(`canView:All`);
      }
    }

    // exclusion filter
    if (this.customerCompanyNumber) {
      filters.push(`NOT excludeCustomerCompany:${this.customerCompanyNumber}`);
    }
    return filters.join(' AND ');
  }
}
